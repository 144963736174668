$header-base-height: 60px;
$header-icon-br: 35px;

.header {
    @include flex(column, nowrap, center);
    background-color: $thm-light-grey;
    // color: $thm-primary;
    font-size: $thm-font-medlrg;
    // height: $header-base-height;
    box-shadow: $thm-bs-1;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: $thm-z-ceiling;
}

.header__container {
    @include flex(row, nowrap, space-between, center);
    // padding: 0 150px;
    // width: 98%;
}

@media only screen and (max-width: 1250px) {
    .header__container {
        padding: 10px;
        background-color: $thm-light-grey;
        box-shadow: $thm-bs-1;

    }
    .header {
        display: block;
    }
}

.header__logo {
    @include flex(row, nowrap, center, center);
    text-decoration: none;
    margin-left: 15px;
}

.header__logo-text {
    font-weight: bold;
}

.header__logo-icon {
    height: 70px;
    padding: 15px;
    margin-right: .75rem;
}

.header__toggle {
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    // display: none;
}

.header__toggle-bars {
    @include transition();
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 3px;
    background-color: $thm-charcoal;
    border-radius: $header-icon-br;
    &:before {
        @include transition;
        content: "";
        position: absolute;
        top: 10px;
        display: block;
        width: 25px;
        height: 3px;
        background-color: $thm-charcoal;
        border-radius: $header-icon-br;
    }
    &:after {
        content: "";
        @include transition;
        position: absolute;
        bottom: 10px;
        display: block;
        width: 25px;
        height: 3px;
        background-color: $thm-charcoal;
        border-radius: $header-icon-br;
    }
    @include bp($thm-bp-lrgxlg) {
        display: none;
    }
}

.header__toggle.open .header__toggle-bars {
    // background-color: $thm-primary;
    width: 0;
    &:before {
        transform: rotate(45deg);
        top: 0;
    }
    &:after {
        transform: rotate(-45deg);
        top: 0;
    }
}
