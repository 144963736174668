/**
* Global variabls contain information
* related styling of the application
*/
* {
    box-sizing: content-box !important;
}

// 1. Colors
$thm-white: #fff;
$thm-off-white: #f5f5f5;
$thm-charcoal: #212121;
$thm-grey: #3e3d3d;
$thm-light-grey: #eee;
$thm-blue: #176778;
$thm-dark-blue: #073B46;
$thm-green: #519050;

$link-color: #23A6C3;
$link-color-hover:#176778;

// 2. Fonts
$thm-font-primary: 'Open Sans', sans-serif; 

// Font Size
$thm-font-tiny: 12px;
$thm-font-sml: 14px;
$thm-font-med: 16px;
$thm-font-medlrg: 18px;
$thm-font-lrg: 24px;
$thm-font-xlrg: 28px;
$thm-font-ult: 30px;
$thm-font-xult: 40px;
$thm-font-xxult: 70px;

// Default Font-Heading
h1{font-size: $thm-font-xxult;}
h2{font-size: $thm-font-lrg;}
h3{font-size: $thm-font-medlrg;}
h4{font-size: $thm-font-medlrg}


// Font Weight 
$thm-fw-1: 100;
$thm-fw-2: 300;
$thm-fw-3: 400;
$thm-fw-4: 700;


// 3. Spacing (padding/margins)

$thm-spacing-tiny: 5px;
$thm-spacing-sml: 10px;
$thm-spacing-med: 15px;
$thm-spacing-medlrg: 20px;
$thm-spacing-lrg: 25px;
$thm-spacing-xlrg: 30px;
$thm-spacing-ult: 40px;
$thm-spacing-xult: 55px;
$thm-spacing-xxult: 70px;

$thm-spacing-tiny-neg: -5px;
$thm-spacing-sml-neg: -10px;
$thm-spacing-med-neg: -15px;
$thm-spacing-medlrg-neg: -20px;
$thm-spacing-lrg-neg: -25px;
$thm-spacing-xlrg-neg: -30px;
$thm-spacing-ult-neg: -40px;
$thm-spacing-xult-neg: -55px;
$thm-spacing-xxult-neg: -70px;

// 4. Z-index
$thm-z-floor: 0;
$thm-z-glide: 5;
$thm-z-float: 10;
$thm-z-hover: 20;
$thm-z-alert: 80;
$thm-z-ceiling: 100;

// 5. Box Shadow
$thm-bs-1: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$thm-bs-2: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$thm-bs-3: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

// 6. Border Radius
$thm-br-1: 5px;
$thm-br-2: 10px;
$thm-br-3: 15px;

// 7. Sections
.section {
    max-width: 1300px;
    margin: auto;
    padding: 0 15px;
    padding-top: $thm-spacing-ult;
}

.grey-background {
    background-color: $thm-off-white;
}

// 8. Links 
.link {
    color: $link-color; 
    text-decoration: none;
}
.link:hover {
    color: $link-color-hover;
    text-decoration: underline;
}

// 9. Breakpoints (subject to change)
$thm-bp-tiny: 350px;
$thm-bp-sml: 630px;
$thm-bp-smlmed: 725px;
$thm-bp-med: 800px;
$thm-bp-medlrg: 940px;
$thm-bp-lrg: 1024px;
$thm-bp-lrgxlg: 900px;
$thm-bp-xlrg: 1280px;
$thm-bp-ult: 1440px;