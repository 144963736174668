.course__standards-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-content: center;
    justify-content: center;
}
.course__standards-flex-box {
    width: 100%;
    background-color: $thm-white;
    box-shadow: $thm-bs-1;
    padding: 30px;
    margin: 10px;
}
#standards .course__standards-flex .course__h3-gen-heading {
    text-align: center;
    margin-top: 0;
}
//ul
.section ul {
    list-style: none;
  }
.section ul li::before {
    content: "\2022";
    color: $thm-blue;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
.course__standards-flex ul {
    margin-left: -15px;
}
.course__general-info-flex ul {
    margin-left: -10px;
}
//media 
@media only screen and (max-width: $thm-bp-lrg) {
    .course__standards-flex-box {
        width: 40%;
    }
    .course__standards-flex {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: $thm-bp-smlmed) {
    .course__standards-flex-box {
        width: 100%;
        margin-bottom: 15px;
    }
}
