.filter__section {
  background-color: $thm-light-grey;
  padding-bottom: 25px;
  box-shadow: $thm-bs-1;
  border-radius: 8px;
}
.filter__remove-padding {
  padding-top: 5px;
}
.filter__filters-flex-box {
  display: flex;
  flex-direction: column;
}
//
.filter__remove-padding h3 {
  margin: 0 15px;
  font-size: 18px;
  // text-transform: uppercase;
  letter-spacing: 0.8px;
  // color: $thm-blue;
  font-weight: bolder;
}
//
.filter__input-div {
  display: flex;
  margin: 8px 0;
}
.filter__filter-container {
  margin: 15px;
  margin-bottom: 25px;
}
//
.filter__button-filter {
  width: 88%;
  font-size: 16px;
  text-align: left;
  padding: 15px;
  padding-bottom: 10px;
  border: none;
  border-bottom: 1px solid #a5a6a76b;
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
}
.filter__icon {
  padding-top: 0 !important;
  font-size: 18px !important;
  margin-left: 8px !important;
}
.filter__icon-hide {
  text-align: left !important;
  margin-bottom: 0 !important;
  font-size: 14px !important;
}
@media only screen and (max-width: 950px) {
  .filter__button-filter {
    width: 85%;
  }
}
@media only screen and (max-width: $thm-bp-med) {
  .filter__filters-flex-box {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .filter__padding-top-nthchild {
    width: 23%;
  }
}
@media only screen and (max-width: 750px) {
  .filter__padding-top-nthchild {
    width: 50%;
  }
}
@media only screen and (max-width: 450px) {
  .filter__padding-top-nthchild {
    width: 98%;
  }
  .filter__button-filter {
    width: 93%;
  }
}
