.course__icon-container {
  display: flex;
}
.course__icon {
  margin-left: 15px;
  font-size: 25px;
  color: $thm-blue;
  padding-top: 8px;
  position: relative;
  display: inline-block;
}

//hover
.course__icon .course__icon-hide {
  visibility: hidden;
  width: 250px;
  background-color: $thm-grey;
  color: $thm-white;
  text-align: center;
  border-radius: $thm-br-1;
  padding: 10px;
  font-size: $thm-font-med;
  box-shadow: $thm-bs-1;
  opacity: 0;
  transition: opacity 0.5s;

  /* Position*/
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
  margin-bottom: -10px;
}
.course__icon:hover {
  color: $thm-charcoal;
  cursor: pointer;
}
.course__icon:hover .course__icon-hide {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: $thm-bp-medlrg) {
    .course__icon .course__icon-hide { 
        margin-left: -130px;
    }
}
