.home__flex-box {
    display: flex;
    justify-content: space-between;
}
.home__flex-child {
    background-color: $thm-light-grey;
    box-shadow: $thm-bs-1;
    width: 32%;
    border-radius: $thm-br-2;
    padding: $thm-spacing-xult $thm-spacing-medlrg;
    text-decoration: none;
    color: $thm-charcoal;
    margin: $thm-spacing-sml;
    transition: all .4s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.home__flex-child:hover {
    transform: scale(1.02);
}
.home__flex-child h2 {
    margin: 0;
    margin-bottom: $thm-spacing-tiny-neg;
    margin-top: $thm-spacing-medlrg;
}
.home__flex-child p {
    text-align: center;
    font-size: $thm-font-sml;
}
.home__section {
    height: 70vh;
    display: flex;
    align-items: center;
    padding-top: 0;
}

@media only screen and (max-width: 1000px) {
    .home__flex-box {
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .home__section {
        height: auto;
        padding-top: $thm-spacing-ult;
    }
    .home__flex-child {
        width: 40%;
    }
}

@media only screen and (max-width: 650px) {
    .home__flex-child {
        width: 100%;
    }
}