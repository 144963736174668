.links__section h1{
    text-align: center;
    margin: 0;
}

.links__div-link-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.links__div-link-container .link {
    margin-top: 50px;
    font-size: $thm-font-lrg;
    text-align: center;
}