.nav {
    @include transition;
    height: 0;
    position: absolute;
    overflow: hidden;
    width: 100vw;
    top: 50px;
    background-color: $thm-light-grey;
    z-index: $thm-z-ceiling;
    padding-right: 0px;


    &.open {
        height: 100vh;
        // width: 100vw;

        @include bp($thm-bp-lrgxlg) {
            transition: none;
            height: auto;
            top: 17px;
            background-color: transparent;
        }
    }
    @include bp($thm-bp-xlrg) {
        // padding-right: 150px;

    }
    @include bp($thm-bp-lrgxlg) {
        height: 100%;
        top: 0;
        right: 1vw;
        width: auto;
        overflow: visible;
        display: flex;
        align-items: center;
    }
}
@media only screen and (max-width: $thm-bp-lrgxlg) {
    .nav {
        top: 120px
    }
}

.nav__list {
    list-style-type: none;

    @include bp($thm-bp-lrgxlg) {
        @include flex(row, nowrap, space-between, center);
        padding: 0;
        margin: 0;
        height: 60px;
    }
}

.nav__item {
    display: block;
    position: relative;
    margin-left: 1em;
    cursor: pointer;
    padding: .75em 0;

    &:active {
        color: $thm-charcoal;
    }

    @include bp($thm-bp-smlmed) {
        border: none;
        position: relative;

        &:active {
            background-color: transparent;
        }

        &:after {
            @include transition;
            content: '';
            min-width: 0;
            max-width: 0;
            position: absolute;
            right: 50%;
            bottom: 5px;
            background-color: $thm-blue;
        }

        &:hover {
            &:after {
                min-width: 100%;
                max-width: 100%;
                right: 0;
            }
        }
    }
}

.nav__item:nth-child(1) {
    margin-top: -10px;

    @include bp($thm-bp-smlmed) {
        margin-top: 0;
    }
}


.nav__link {
    display: block;
    position: relative;
    // border-bottom: 2px solid $thm-blue;
    font-size: $thm-font-medlrg;
    padding: 20px;
    text-decoration: none;
    color: $thm-charcoal;
    // font-weight: bold;
    cursor: pointer;
    

    &:active {
        color: $thm-charcoal;
    }

    @include bp($thm-bp-smlmed) {
        font-size: $thm-font-med;
        border: none;
        position: relative;

        &:active {
            background-color: transparent;
        }

        &:after {
            @include transition;
            content: '';
            height: 2px;
            // width: 0;
            min-width: 0;
            max-width: 0;
            position: absolute;
            right: 50%;
            bottom: 3px;
            background-color: $thm-blue;
        }

        &:hover {
            &:after {
                min-width: 100%;
                max-width: 100%;
                right: 0;
            }
        }
    }
}

.nav__sub-list-icon {
    @include transition;
}

.nav__item:hover .nav__sub-list-icon {
    transform: rotate(90deg);
}

.nav__item:hover .nav__sub-list-wrapper {
    @include bp($thm-bp-smlmed) {
        overflow: visible;
    }
}

.nav__p-postScript {
    display: none;

    @include bp($thm-bp-medlrg) {
        display: inherit;
        margin-left: .25em;
        font-size: $thm-font-sml;
        margin-bottom: 0;
        color: $thm-off-white;
        // font-weight: bold;
    }
}

.selected {
    @include bp($thm-bp-smlmed) {
        &:after {
            @include transition;
            content: '';
            height: 2px;
            min-width: 100%;
            max-width: 100%;
            position: absolute;
            right: 0;
            bottom: 3px;
            background-color: $thm-blue;
        }
    }
}

