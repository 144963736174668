
//General info
.course__general-big-flex-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

/*table of contents*/
.course__table-of-contents {
    width: 30%;
    height: auto;
    background-color: $thm-white;
    padding: 20px;
    box-shadow: $thm-bs-1;
    margin-right: 35px;
}
.course__table-of-contents h3 {
    margin: 0;
    font-size: $thm-font-lrg;
    letter-spacing: 1.5px;
    text-align: center;
}
.course__table-of-contents a {
    color: $link-color;
    text-decoration: none;
    margin: 15px;
}
.course__table-of-contents ol {
    margin: 0;
    margin-left: -1em;
}

/*General info*/
#general-info {
    width: 70%;
}
.course__general-info-flex {
    display: flex;
    flex-wrap: wrap; 
    gap: 15px;   
    // margin-top: -50px;
}
.course__general-info-flex-box {
    width: 48%;
    margin-bottom: 30px;
}

/*Media Query*/ 
@media only screen and (max-width: $thm-bp-medlrg) {
    .course__general-big-flex-box {
        flex-direction: column;
    }
    .course__table-of-contents {
        width: auto;
        margin-bottom: 30px;
        margin-right: 0;
    }
    #general-info {
        width: 100%;
    }
}

@media only screen and (max-width: $thm-bp-smlmed) {
    .course__general-info-flex-box {
        width: 100%;
    }
}