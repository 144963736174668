//
.masterList__filter-course-grid {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 25px;
    align-items: start;
}
//display none filter 
.modal {
    width: 200px;
    height: 200px;
    background-color: red;
  }
//spinner 
.masterList__spinner {
    margin: auto;
    position: relative;
    left: 79%;
}

//
.masterList__section-flexbox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
}
.masterList__courses-container {
    box-shadow: $thm-bs-1;
    border-radius: $thm-br-2;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    color: $thm-charcoal;
    transition: all .3s ease-in-out;
}
.masterList__courses-container:hover {
    transform: scale(1.02);
}
.masterList__courses-decoration-none {
    text-decoration: none;
    color: $thm-charcoal;
}
//
.masterList__container-padding {
    margin: 0 20px;
}
.masterList__courses-container h2 {
    color: $thm-blue;
    margin-bottom: 8px;
}
.masterList__div-color-block {
    width: 100%;
    height: 3px;
    background: rgb(23,103,120);
    background: linear-gradient(140deg, rgba(23,103,120,1) 0%, rgba(177,218,174,1) 100%);
}
.masterList__catagory-box {
    margin: 0 20px;
}
.masterList__catagory-box h3 {
    font-size: $thm-font-med;
    margin-bottom: 0px;
    margin-top: 12px;
}
.masterList__catagory-box p {
    margin: 3px 0;
    font-size: $thm-font-sml;
}

//
@media only screen and (max-width: 1150px) {
    .masterList__section-flexbox {
        grid-template-columns: 1fr 1fr;
    }
    .masterList__spinner {
        left: 70%;
    }
    .masterList__filter-course-grid {
        grid-template-columns: 1fr 3fr;
    }
}
@media only screen and (max-width: $thm-bp-medlrg) {
    .masterList__spinner {
        left: 50%;
    }
}
@media only screen and (max-width: $thm-bp-med) {
    .masterList__section-flexbox {
        grid-template-columns: 1fr 1fr;
    }
    .masterList__spinner {
        left: 40%;
    }
    .masterList__filter-course-grid {
        grid-template-columns: 1fr;
    }
}
@media only screen and (max-width: $thm-bp-sml) {
    .masterList__section-flexbox {
        grid-template-columns: 1fr;
    }
    .masterList__spinner {
        position: relative;
        left: 0%;
        width: 98%;    
    }
}