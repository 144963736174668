.masterBanner__banner-container{
    // width: 100%;
    color: $thm-white;
    background: rgb(23,103,120);
    background: linear-gradient(140deg, rgba(23,103,120,1) 0%, rgba(177,218,174,1) 100%);
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.masterBanner__banner-container h1 {
    margin: 0;
    text-align: center;
    line-height: 1.2;
}
.masterBanner__banner-container h2 {
    margin-top: 5px;
    margin-bottom: 0;
}
.masterList__search {
    margin-top: 40px;
    width: 40%;
    border: none;
    border-radius: 100px;
    text-align: left;
    font-size: 20px;
    background-color: #D3E5E0;
    // text-transform: capitalize;
}
input[type="text" i] {
    padding: 10px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
@media only screen and (max-width: $thm-bp-med) {
    .masterBanner__banner-container h1 {
        font-size: $thm-font-xult;
    }
    .masterBanner__banner-container h2 {
        font-size: $thm-font-medlrg;
    }
    .masterList__search {
        width: 90%;
    }
}
