hr {
    opacity: .3;
}
.course__h3-gen-heading {
    margin-bottom: 5px;
    // text-transform: uppercase;
    font-size: $thm-font-med;
}
.course__h2-blue-heading {
    margin: 0;
    margin-bottom: -5px;
    color: $thm-blue;
    font-size: $thm-font-xult;
}