#training-type {
    margin-top:-30px;
}
#training-type table, #prices table, #updates table {
    width: 100%;
    background-color: #f3f3f3;
    border-collapse: collapse;
    text-align: center;
}
th, td {
    border: 1px solid #d1d1d1;
    padding: 12px;
}
th {
    background-color: $thm-white;
}

@media only screen and (max-width: $thm-bp-sml) {
    th {
        writing-mode: vertical-rl;
        text-orientation: mixed;
    }
}
@media only screen and (max-width: 625px) {
}